@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --header-padding-default: 10px;
  --logo-height-default: 32px;
  --logo-height-md: 48px;

  --header-height-default: calc((var(--header-padding-default) * 2) + var(--logo-height-default));
  --header-height-md: calc((var(--header-padding-default) * 2) + var(--logo-height-md));

  --game-list-config-height-default: 48px;
}

@layer base {
  @font-face {
    font-family: "Helvetica Neue";
    font-weight: 400;
    src: url("../public/fonts/HelveticaNeue-Roman.otf");
  }
  @font-face {
    font-family: "Helvetica Neue";
    font-weight: 500;
    src: url("../public/fonts/HelveticaNeue-Medium.otf");
  }
  @font-face {
    font-family: "Helvetica Neue";
    font-weight: 700;
    src: url("../public/fonts/HelveticaNeue-Heavy.otf");
  }

  html {
    @apply text-black;
  }
}

@layer components {
  .heavy {
    @apply font-Altivo font-black uppercase text-black;
  }

  .heading-1 {
    @apply font-Altivo text-3xl font-black uppercase text-black;
  }

  .heading-2 {
    @apply font-Altivo text-2xl font-black uppercase text-black;
  }

  .heading-3 {
    @apply font-Altivo text-xl font-black uppercase text-black;
  }

  .header-banner {
    @apply text-sm font-black uppercase;
  }
}
